.navbar {
  position: fixed;
  width: 100vw;
  background-color: rgb(231, 234, 237);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-end {
  display: flex;
  justify-content: space-around;
  margin-left: 60%;
  margin-right: 50px;
  margin-top: 10px;
  width: 100%;
}

.title {
  margin: 10px 50px;
  font-family: "Hind", sans-serif;
}
.hamburger-menu-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 60px;
  left: 100%;
  overflow-x: hidden;
  background-color: #8b8888;
  min-width: 160px;
  height: fit-content;
  border: 1px solid black;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}
.show-hamburger {
  display: flex;
  flex-direction: column;
  transform: translateX(-120%);
  overflow-x: hidden;
  /* left: -100px; */
}
.hamburger-links {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(231, 234, 237);
  /* margin: 25px; */
  /* border: 1px solid black; */
  width: 100%;
  height: 60px;
}

.hamburger-links:hover {
  background-color: rgba(255, 235, 205, 0.521);
  color: black;
}

.link-text {
  color: rgb(231, 234, 237);
  font-weight: bold;
  text-decoration: none;
}
.valume-container {
  position: relative;
}
.volume {
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 10px 50px;
}
.volume-mute {
  position: absolute;
  display: none;
  width: 30px;
  height: 30px;
  margin: 10px 50px;
}

.nav-resume-btn {
  margin: 10px 40px;
}

@media screen and (max-width: 700px) {
  .nav-resume-btn {
    font-size: 10px;
    height: 40px;
    width: 100px;
    margin: 10px 40px;
  }
}
@media screen and (max-width: 400px) {
  .logo {
    margin-top: 5px;
  }
}
