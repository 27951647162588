.layout-parent {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layout-children {
  /* height: 100%; */
  overflow: auto;
  text-align: center;
  margin-top: 60px;
}
