.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  background-color: rgb(90, 79, 79);
  margin-top: 50px;
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 30px;
  padding: 20px;
  width: 45%;
}

.about-photo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 30px;
  padding: 20px;
  width: 50%;
  /* box-shadow:2px 2px 10px black; */
}

.about-title {
  color: rgb(231, 234, 237);
  font-size: 25px;
  margin-bottom: 1em;
  font-weight: bold;
  text-shadow: 2px 2px 10px black;
}

.about-paragraph {
  color: rgb(231, 234, 237);
  font-size: 15px;
  text-align: left;
  text-shadow: 2px 2px 10px black;
}

.will-image {
  width: 400px;
  box-shadow: 2px 2px 10px black;
  border-radius: 5px;
}

@media screen and (max-width: 875px) {
  .about-container {
    display: flex;
    flex-direction: column;
  }
  .about-paragraph {
    font-size: 14px;
  }
  .about-text {
    margin: 0px 30px 0px 30px;
    width: 75%;
  }
  .about-photo {
    margin-top: 0;
  }
}
