.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  bottom: 0;
}

.home-title {
  color: rgb(231, 234, 237);
  text-shadow: 2px 2px 10px black;
  font-size: 50px;
  font-family: "Mandali", sans-serif;
  font-weight: bold;
  margin-top: 50px;
}

.bubble {
  width: 125px;
  height: 125px;
}

.sub {
  color: rgb(231, 234, 237);
  font-size: 30px;
  margin-left: 20px;
  font-family: "Mandali", sans-serif;
  text-shadow: 2px 2px 10px black;
}

.top-photo {
  margin: 50px auto;
  border-radius: 5px;
  box-shadow: 2px 2px 20px rgb(15, 15, 15);
  width: 50%;
}

.top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.drum-machine-and-volume-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.drum-machine {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  max-width: 400px;
  height: auto;
}
.drum-button-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
}

button.drums {
  margin: 20px 10px;
  width: 60px;
}

.section {
  padding: 50px;
  color: rgb(231, 234, 237);
}

.info-section {
  color: rgb(231, 234, 237);
  text-shadow: 2px 2px 10px black;
  font-family: "Mandali", sans-serif;
  margin: 10px;
}

.drum-machine-text {
  font-family: Mandali, sans-serif;
  font-weight: bold;
}

/* .volume-slider-container {
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  margin-left: -50px;
  height: 60px;
  width:350px;
}
.slider {
  margin-left: 0
} */

@media screen and (max-width: 500px) {
  .home-title {
    text-shadow: 2px 2px 10px black;
    font-size: 36px;
  }

  .sub {
    font-size: 20px;
    text-shadow: 2px 2px 10px black;
  }

  .bubble {
    width: 100px;
    height: 100px;
  }
}
