.projects-container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 50%;
  max-width: 1080px;
  background-color: rgb(90, 79, 79);
  margin-top: 60px;
  box-sizing: border-box;
}
.projects-title {
  color: rgb(231, 234, 237);
  font-size: 25px;
  margin-bottom: 1em;
  font-weight: bold;
  text-shadow: 2px 2px 10px black;
}
.project-link {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: fit-content;
  /* margin: 50px auto; */
}
/* .projects-section {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
} */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  /* margin: 50px auto; */
  width: 100%;
  height: fit-content;
}

.overlay-container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.project-img {
  /* margin: 20px;
  width: 360px;
  height: 250px;
  box-shadow: 2px 2px 20px rgb(73, 73, 73); */
  /* position: relative; */
  margin: 50px auto;
  border-radius: 5px;
  box-shadow: 2px 2px 20px rgb(15, 15, 15);
  width: 100%;
  height: 100%;
}

.overlay {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  background: rgb(240, 248, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0f8ff",endColorstr="#f0f8ff",GradientType=1);
  /* top: 10%; */
  left: 0;
  right: 0;
  /* margin: 50px auto; */
  /* border: 2px solid black; */
  border-radius: 5px;
  /* box-shadow: 2px 2px 20px rgb(15, 15, 15); */
  width: 100%;
  height: 80%;
  opacity: 0;
  transition: 0.5s ease;
  cursor: pointer;
}

.art-wall-overlay {
  top: 13%;
  height: 75%;
}
.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.title {
  margin: 0 10px;
}
.bold {
  font-weight: bold;
  color: darkblue;
}
.project-description {
  font-size: 15px;
}

.image-container:hover .overlay {
  opacity: 0.9;
  /* background-color: aliceblue; */
  background: rgb(240, 248, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 0) 0%,
    rgba(240, 248, 255, 1) 25%,
    rgba(240, 248, 255, 1) 75%,
    rgba(240, 248, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0f8ff",endColorstr="#f0f8ff",GradientType=1);
}

@media screen and (max-width: 700px) {
  .projects-container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0 auto;
    width: 75%;
    max-width: 1080px;
    background-color: rgb(90, 79, 79);
    margin-top: 50px;
    box-sizing: border-box;
  }
  .project-link {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    /* width: 100 */
    position: relative;
    width: 100%;
    height: 100%;
    /* margin: 50px auto; */
  }

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    /* margin: 50px auto; */
    width: 100%;
    height: fit-content;
  }

  .overlay {
    height: 70%;
  }
  .project-img {
    border-radius: 5px;
    box-shadow: 2px 2px 20px rgb(15, 15, 15);
    width: 100%;
    height: 100%;
  }
}
