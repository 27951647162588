/* .volume-container {
  padding: 100px;
} */
.volume-slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -50px;
  height: 60px;
  width: 350px;
}
.slider {
  margin-left: 0;
  margin-bottom: 7px;
}
